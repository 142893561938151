<template>
  <component
    :is="to ? 'router-link' : 'a'"
    class="app-link"
    :to="to"
    :href="href"
  >
    <p class="link-title">
      <slot />
    </p>
    <div class="wrapper-divider">
      <div class="divider" />
      <icon-half-arrow-right class="icon" />
    </div>
  </component>
</template>

<script>
import IconHalfArrowRight from '@/assets/img/icons/half-arr-right.svg'

export default {
  name: 'AppLink',
  components: {
    IconHalfArrowRight
  },
  props: {
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-link {
    cursor: pointer;
    display: block;
    .link-title {
      font-weight: 500;
    }
    .wrapper-divider {
      position: relative;
      .divider {
        width: calc(100% - 30px);
        margin-bottom: 0;
        margin-top: 8px;
        @include max-w-lg {
          margin-top: 8px;
        }
        @include max-w-xs {
          margin-top: 4px;
        }
      }
      .icon {
        position: absolute;
        right: 0;
        top: calc(50% - 13px);
        @include max-w-xs {
          right: 0px;
        }
      }
    }
  }
</style>
<template>
  <modal
    :name="modalName"
    height="auto"
    :max-width="582"
    :adaptive="true"
    class="modal"
    @opened="opened"
    @before-close="beforeClose"
  >
    <button class="btn-close" :class="{ active : isOpened }" @click="$modal.hide(modalName)" />
    <div class="wrapper-content">
      <p class="modal-title fs--medium mb-xs">
        Заказ успешно оплачен
      </p>
      <p class="mb-l description">
        # заказа 122345242
      </p>
      <p class="fw--light">
        Вы можете получить его на почте, информация выслана на вашу почту, благодарим за участие, ждем вас снова!
      </p>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ModalOrderSuccessful',
  data () {
    return {
      modalName: 'modal-order-successful',
      isOpened: false
    }
  },
  methods: {
    opened () {
      this.isOpened = true
    },
    beforeClose () {
      this.isOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
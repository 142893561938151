<template>
  <modal
    :name="modalName"
    height="auto"
    :adaptive="true"
    class="modal"
    @opened="opened"
    @before-close="beforeClose"
  >
    <button class="btn-close" :class="{ active : isOpened }" @click="$modal.hide(modalName)" />
    <div class="wrapper-content">
      <p class="modal-title fs--medium mb-xs">
        Сообщить о находке
      </p>
      <p class="description mb-l">
        Введите данные, если вы нашли вещь или питомца со стикером CLICK
      </p>
      <p class="description-light fs--small mb-l">
        С услугой «Ключи на месте» Вы минимизируете риски от потери своих ключей! Брелок CLICKTOID имеет уникальный ID номер, информацию для Нашедшего и телефон круглосуточной Службы поддержки.
      </p>
      <p class="modal-title fs--medium">
        Введите ID номер
      </p>
      <div class="divider" />
      <p class="mb-m">
        Укажите данные на стикере.
      </p>
      <input-text class="mb-l" placeholder="ID номер" required />
      <app-button title="Отправить данные" description="Мы свяжемся с вами сразу как найдем владельца">
        <icon-arrow-right />
      </app-button>
    </div>
  </modal>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import InputText from '@/components/input/InputText'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'

export default {
  name: 'ModalFinding',
  components: {
    AppButton,
    InputText,
    IconArrowRight
  },
  data () {
    return {
      modalName: 'modal-finding',
      isOpened: false
    }
  },
  methods: {
    opened () {
      this.isOpened = true
    },
    beforeClose () {
      this.isOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-text {
    width: 100%;
  }
</style>

<template>
  <transition name="drawer">
    <div v-if="drawerVisible" class="overlay">
      <button class="close-drawer" @click="closeDrawer" />
      <div v-click-outside="closeDrawer" class="mobile-drawer scrollable-content">
        <div class="wrapper">
          <div class="wrapper-header mb-m">
            <div class="drawer-header">
              <router-link class="logo-link" to="/#home">
                <logo-svg class="logo-img" />
              </router-link>
              <router-link class="user-link" to="/auth">
                <icon-user class="icon-user" />
              </router-link>
            </div>
          </div>
          <app-button
            class="mb-xl"
            title="Сообщить о находке"
            description="Если вы нашли вещь с меткой, перейдите по данной кнопке"
            @click.native="openModal"
          >
            <icon-product class="icon" />
          </app-button>
          <div class="mobile-navigation mb-xl">
            <ul class="navigation-list">
              <li class="navigation-item">
                <router-link class="navigation-link" to="/#about">
                  <icon-info class="icon" />
                  <span>О компании</span>
                </router-link>
              </li>
              <li class="navigation-item">
                <router-link class="navigation-link" to="/#products">
                  <icon-product class="icon" />
                  <span>Продукты</span>
                </router-link>
              </li>
              <li class="navigation-item">
                <router-link class="navigation-link" to="/#footer">
                  <icon-question class="icon" />
                  <span>Как это работает</span>
                </router-link>
              </li>
              <!--
              <li class="navigation-item">
                <router-link class="navigation-link" to="/activation#hero">
                  <icon-debug class="icon" />
                  <span>Активировать</span>
                </router-link>
              </li>
              <li class="navigation-item">
                <router-link class="navigation-link" to="/renewal#hero">
                  <icon-clock class="icon icon-clock" />
                  <span>Продлить</span>
                </router-link>
              </li>
              -->
            </ul>
          </div>
          <app-button class="btn-call mb-l" title="8 800 4444308" description="Консультация и поддержка клиентов">
            <icon-call class="icon-call" />
          </app-button>
          <app-button class="btn-call mb-xl" title="8 499 6854308" description="Help & customer support">
            <icon-call class="icon-call" />
          </app-button>
          <div class="wrapper-links mb-l">

            <router-link class="menu-link" to="/login">
              Вход для партнеров
            </router-link>
            <router-link class="menu-link" to="/collaboration">
              Сотрудничество
            </router-link>
            <router-link class="menu-link" to="/articles">
              Статьи
            </router-link>
            <router-link class="menu-link" to="/contacts">
              Контакты
            </router-link>
            <router-link class="menu-link" to="/requisites">
              Реквизиты
            </router-link>
            <router-link class="menu-link" to="/faq#pay">
              Оплата
            </router-link>
            <!--
            <router-link class="menu-link" to="/agreements">
              Соглашения
            </router-link>
            <a class="menu-link" to="/change#hero">Смена держателя</a>
            <router-link class="menu-link" to="/faq#delivery">
              Доставка
            </router-link>
            <router-link class="menu-link" to="/auth">
              Личный кабинет
            </router-link>
            -->
          </div>
          <div class="wrapper-footer-logo mb-s">
            <logo-svg class="footer-logo" />
          </div>
          <p class="fs--small rights mb-s">
            © 2024 CLICK2ID.RU, All rights reserved.
          </p>
          <div class="wrapper-social mb-s">
            <a class="social-link" href="">
              <icon-vk class="icon-social vk" />
            </a>
          </div>
          <div class="wrapper-links fs--small fw--light mb-xs">
            <!--
            <router-link class="menu-link" to="/information">
              Раскрытие информации
            </router-link>
            -->
            <a
            class="menu-link"
            href="assets/Политика конфиденциальности.pdf"
            target="_blank"
            rel="noopener"
          >
            Политика конфиденциальности
          </a>
          <a
            class="menu-link"
            href="assets/Заявление.pdf"
            target="_blank"
            rel="noopener"
          >
            Заявление о конфиденциальности
          </a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

import AppButton from '@/components/common/AppButton'
import IconUser from '@/assets/img/icons/user.svg'
import IconInfo from '@/assets/img/icons/info.svg'
import IconProduct from '@/assets/img/icons/product.svg'
import IconQuestion from '@/assets/img/icons/question.svg'
//import IconDebug from '@/assets/img/icons/debug.svg'
//import IconClock from '@/assets/img/icons/clock.svg'
import IconCall from '@/assets/img/icons/call.svg'
import IconVk from '@/assets/img/icons/vk.svg'
//import IconInsta from '@/assets/img/icons/insta.svg'
//import IconYoutube from '@/assets/img/icons/youtube.svg'
//import IconFacebook from '@/assets/img/icons/facebook.svg'
import LogoSvg from '@/assets/img/logo.svg'

export default {
  name: 'AppMobileDrawer',
  components: {
    AppButton,
    IconUser,
    IconInfo,
    IconProduct,
    IconQuestion,
    //IconDebug,
    //IconClock,
    IconCall,
    IconVk,
    //IconInsta,
    //IconYoutube,
    //IconFacebook,
    LogoSvg
  },
  computed: {
    ...mapState(['drawerVisible'])
  },
  methods: {
    openModal () {
      this.$store.dispatch('setUnvisibleDrawer')
      this.$modal.show('modal-finding')
    },
    closeDrawer () {
      if (this.drawerVisible) {
        this.$store.dispatch('setUnvisibleDrawer')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .drawer-enter-active, .drawer-leave-active {
    transition: all .3s;
  }
  .drawer-leave-to, .drawer-enter {
    opacity: 0 !important;
    .mobile-drawer {
      left: -400px !important;
    }
    .close-drawer {
      right: -200px !important;
    }
  }
  .icon {
    width: 20px;
    height: 20px;
    &.icon-clock {
      width: 16px;
      height: 16px;
    }
  }
  .close-drawer {
    z-index: 9999;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
    transition: .3s;
    @include max-w-xs {
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      &::before, &::after {
        width: 14px;
        height: 2px;
        top: 10px;
      }
    }
    &::before, &::after {
      content: '';
      width: 28px;
      height: 2px;
      border-radius: 1px;
      background: #FFF6ED;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      @include max-w-xs {
        width: 18px;
      }
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
  .overlay {
    opacity: 1;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, .6);
  }
  .mobile-drawer {
    overflow: auto;
    background: #FFF6ED;
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    width: 360px;
    height: 100%;
    padding: 0 24px;
    transition: .3s;
    @include max-w-xs {
      padding: 0 16px;
      width: 280px;
    }
    .wrapper-header {
      height: 74px;
      display: flex;
      align-items: center;
      @include max-w-xs {
        height: 56px;
      }
      .drawer-header {
        display: flex;
        align-items: center;
        width: 100%;
        .logo-link {
          display: flex;
          align-items: center;
          justify-content: center;
          .logo-img {
            width: 52px;
            height: 24px;
          }
        }
        .user-link {
          margin-left: auto;
        }
      }
    }
    .mobile-navigation {
      background: $white;
      padding: 12px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 8px 8px rgba(0, 0, 0, 0.08);
      border-radius: $border-radius-base;
      .navigation-link {
        padding: 8px;
        display: flex;
        align-items: center;
        .icon {
          margin-right: 8px;
        }
      }
    }
    .btn-call {
      .icon-call {
        width: 15px;
        height: 15px;
      }
    }
    .wrapper-links {
      display: flex;
      flex-direction: column;
      .menu-link {
        margin-bottom: 4px;
      }
    }
    .wrapper-footer-logo {
      opacity: .5;
      .footer-logo {
        width: 70px;
        height: 32px;
      }
    }
    .rights {
      font-weight: 500;
    }
    .wrapper-social {
      margin-right: -4px;
      margin-left: -4px;
      display: flex;
      align-items: center;
      .social-link {
        margin: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon-social {
          width: 20px;
          height: 20px;
          &.facebook {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
</style>
<template>
  <div class="wrapper-input">
    <input
      class="input-text"
      :value="value"
      :type="type"
      :required="required"
      :placeholder="required ? false : placeholder"
      @input="onInput"
    >
    <p v-if="required" class="placeholder">
      {{ placeholder }} <span class="required">*</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput() {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper-input {
    position: relative;
    .placeholder {
      position: absolute;
      left: 16px;
      top: 50%;
      transform:translateY(-50%);
      opacity: 1;
      transition: .3s;
      color: #3D3832;
      .required {
        color: #FF5064;
      }
    }
    .input-text {
      width: 100%;
      border: none;
      box-shadow: -1px -1px 1px 0 #fff, 1px 1px 1px 0 #A8A8A8;
      height: 52px;
      padding: 16px;
      border-radius: $border-radius-large;
      background: linear-gradient(180deg, rgba(242, 242, 242, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
      backdrop-filter: blur(12px);
      min-width: 120px;
      @include max-w-lg {
        height: 46px;
      }
      @include max-w-xs {
        height: 40px;
      }
      &[required='required']:valid ~ .placeholder {
        display: none;
      }
    }
  }
</style>